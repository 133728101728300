import React, { useEffect, useState } from 'react';
import './LandingPage.css'; // Import CSS file for landing page styling (if required)
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FaWhatsapp } from "react-icons/fa";
import whykailashvideo from './videos/beautiful view of.mp4'

const LandingPage = () => {
    const [showForm, setShowForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // New state to handle loading

    const toggleForm = () => {
        // Check if form is submitted successfully before closing the modal
        if (!isLoading) {
            setShowForm(!showForm);
        }
    };
    

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        City:'',
        countryCode: '+91' // Default country code
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const phoneNumberWithCode = formData.countryCode + formData.phoneNumber;
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Start loading
        console.log('Form submitted:', formData);
        const data = new FormData();
        data.append('fullName', formData.fullName);
        data.append('email', formData.email);
        data.append('phoneNumber', phoneNumberWithCode);
        data.append('City',formData.City);
        const Sheet_Url = "https://script.google.com/macros/s/AKfycbytLtiIkr_B-LneKEh9foIOmfOIJuiDjHN6W1Z4Or8JupkdTh3APO_oXjucGuuD4YWPzg/exec";
        try {
            await fetch(Sheet_Url, {
                method: 'POST',
                body: data,
                muteHttpExceptions: true,
            });
        
            setFormData({
                fullName: '',
                email: '',
                phoneNumber: '',
                City:'',
                countryCode: '+91'
            });
            alert("Data saved Successfully");
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false); // Stop loading
            // Optionally, close the modal here if needed
            // setShowForm(false);
        }
        
    };
    useEffect(() => {
        const video2 = document.getElementById('video2');
        const video1 = document.getElementById('video1');

        const handleVideo2Play = () => {
            if (video1 && video1.tagName === 'VIDEO' && !video1.paused) {
                video1.pause();
            }
        };

        video2.addEventListener('play', handleVideo2Play);

        return () => {
            video2.removeEventListener('play', handleVideo2Play);
        };
    }, []);
    const countryCodes = [
        { code: '+1', name: 'USA' },
    { code: '+61', name: 'Australia' },
    { code: '+91', name: 'India' },
    { code: '+44', name: 'UK' },
    { code: '+86', name: 'China' },
    { code: '+81', name: 'Japan' },
    { code: '+82', name: 'South Korea' },
    { code: '+49', name: 'Germany' },
    { code: '+33', name: 'France' },
    { code: '+39', name: 'Italy' },
    { code: '+7', name: 'Russia' },
    { code: '+971', name: 'UAE' },
    { code: '+234', name: 'Nigeria' },
    { code: '+92', name: 'Pakistan' },
    { code: '+966', name: 'Saudi Arabia' },
    { code: '+20', name: 'Egypt' },
    { code: '+65', name: 'Singapore' },
    { code: '+34', name: 'Spain' },
    { code: '+82', name: 'South Korea' },
    { code: '+86', name: 'China' },
    { code: '+81', name: 'Japan' },
    { code: '+62', name: 'Indonesia' },
    { code: '+63', name: 'Philippines' },
    { code: '+66', name: 'Thailand' },
    { code: '+84', name: 'Vietnam' },
    { code: '+1', name: 'Canada' },
    { code: '+55', name: 'Brazil' },
    { code: '+54', name: 'Argentina' },
    { code: '+57', name: 'Colombia' },
    { code: '+52', name: 'Mexico' },
    { code: '+58', name: 'Venezuela' },
    { code: '+51', name: 'Peru' },
    { code: '+52', name: 'Mexico' },
    { code: '+593', name: 'Ecuador' },
    { code: '+507', name: 'Panama' },
    { code: '+591', name: 'Bolivia' },
    { code: '+595', name: 'Paraguay' },
    { code: '+598', name: 'Uruguay' },
    { code: '+509', name: 'Haiti' },
    { code: '+502', name: 'Guatemala' },
    { code: '+503', name: 'El Salvador' },
    { code: '+504', name: 'Honduras' },
    { code: '+505', name: 'Nicaragua' },
    { code: '+506', name: 'Costa Rica' },
    { code: '+507', name: 'Panama' },
    { code: '+509', name: 'Haiti' },
    { code: '+507', name: 'Panama' },
    { code: '+51', name: 'Peru' },
    { code: '+54', name: 'Argentina' },
    { code: '+598', name: 'Uruguay' },
    { code: '+595', name: 'Paraguay' },
    { code: '+972', name: 'Israel' },
    { code: '+972', name: 'Israel' },
    { code: '+974', name: 'Qatar' },
    { code: '+968', name: 'Oman' },
    { code: '+962', name: 'Jordan' },
    { code: '+961', name: 'Lebanon' },
    { code: '+963', name: 'Syria' },
    { code: '+964', name: 'Iraq' },
    { code: '+962', name: 'Jordan' },
    { code: '+968', name: 'Oman' },
    { code: '+974', name: 'Qatar' },
    { code: '+967', name: 'Yemen' },
    { code: '+974', name: 'Qatar' },
    { code: '+973', name: 'Bahrain' },
    { code: '+966', name: 'Saudi Arabia' },
    { code: '+965', name: 'Kuwait' },
    { code: '+967', name: 'Yemen' },
    { code: '+977', name: 'Nepal' },
    { code: '+975', name: 'Bhutan' },
    { code: '+960', name: 'Maldives' },
    { code: '+976', name: 'Mongolia' },
    { code: '+91', name: 'India' },
    { code: '+92', name: 'Pakistan' },
    { code: '+93', name: 'Afghanistan' },
    { code: '+94', name: 'Sri Lanka' },
    { code: '+95', name: 'Myanmar' },
    { code: '+98', name: 'Iran' },
    { code: '+964', name: 'Iraq' },
    { code: '+964', name: 'Iraq' },
    { code: '+966', name: 'Saudi Arabia' },
    { code: '+973', name: 'Bahrain' },
    { code: '+98', name: 'Iran' },
    { code: '+961', name: 'Lebanon' },
    { code: '+963', name: 'Syria' },
    { code: '+20', name: 'Egypt' },
    { code: '+218', name: 'Libya' },
    { code: '+251', name: 'Ethiopia' },
    { code: '+252', name: 'Somalia' },
    { code: '+253', name: 'Djibouti' },
    { code: '+254', name: 'Kenya' },
    { code: '+255', name: 'Tanzania' },
    { code: '+256', name: 'Uganda' },
    { code: '+257', name: 'Burundi' },
    { code: '+258', name: 'Mozambique' },
    { code: '+260', name: 'Zambia' },
    { code: '+261', name: 'Madagascar' },
    { code: '+262', name: 'Reunion' },
    { code: '+263', name: 'Zimbabwe' },
    { code: '+264', name: 'Namibia' },
    { code: '+265', name: 'Malawi' },
    { code: '+266', name: 'Lesotho' },
    { code: '+267', name: 'Botswana' },
    { code: '+268', name: 'Swaziland' },
    { code: '+269', name: 'Comoros' },
    { code: '+27', name: 'South Africa' },
    { code: '+291', name: 'Eritrea' },
    { code: '+297', name: 'Aruba' },
    { code: '+298', name: 'Far'}
    ];
    return (
        <div className="landing-page">
            <div className="offer-container">
                <div className='whatsapp-button'>
                   <div className="whatsapp-container">
                   <a  href="https://wa.me/61424388831">
                    <FaWhatsapp  className='whatsapp-icon'/>
                    <p className="whatsapp-text">Contact Us</p>
                   </a>
                  </div>
                </div>
                 <p className="offer-text">"Get a Free Crystal Shiv Linga and A Rudraksha For Joining Us"</p>
            </div>
            <div className="content">
                <h1 className='contenthead'>"Prepare for Your Spiritual Journey"</h1>
                <p className='parahead'>Embarking on the sacred pilgrimage to Kailash is a once-in-a-lifetime experience,
                    and proper preparation is key to ensuring a smooth and memorable journey.</p>

                <div className="heading-container">
                    <h3 className="heading-text">"Our Happy Tourists During Kailash Mansarovar Yatra-2023"</h3>
                </div>
                <div className="blessed-line">
                    <p>This Yatra has blessed Yatris in the following way..</p>
                </div>

              {/* Video container code */}
              <div className="video-row">
                    <div className="video-container">
                        <iframe
                           
                            className="video-element1"                         
                            title="Travel Video 1"
                            src="https://www.youtube.com/embed/0vYsYHlkkcM?"
                            frameBorder="1"
                            allowFullScreen
                            width="100%"
                            height="300"
                            id='video1'
                        
                        />
                    </div>
                    <div className="video-container" >
                        <video
                          
                            width="100%"
                            height="300"
                            controls
                            className="video-element2"
                            id='video2'
                        >
                            <source src={whykailashvideo} type="video/mp4" />
                        </video>
                    </div>
                </div>
         <div >
                   <Button className='formbutton'>
                     <a href="https://wa.me/61424388831" target="_blank" rel="noopener noreferrer"  className="button-link"><span className='call-whatsapp-button-symbol'>&raquo;</span> Call or WhatsApp Now</a>
                   </Button>
                 </div>

                <button onClick={toggleForm} className='connect-with-us ' >Connect With Us</button>
                {showForm && (
                    <div className="popup-form">
                        <span className="close-btn" onClick={toggleForm}>&times;</span>
                        <h2 className="popup-formh2">Place An Enquiry</h2>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label>Enter Your Name</Form.Label>
                                <Form.Control type="text" id="fullName"
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    required />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required />
                            </Form.Group>

                            <Form.Group className="mb-4">
                                <Form.Label>Mobile No</Form.Label>
                                <div className="input-group">
                                <select
                                className="form-select"
                                   value={formData.countryCode}
                                   onChange={(e) => setFormData({ ...formData, countryCode: e.target.value })}
                                 >
                                      {countryCodes.map((country) => (
                                    <option key={country.code} value={country.code}>{`${country.code} (${country.name})`}</option>
                                          ))}
                                 </select>
                                    <Form.Control type="tel" id="phoneNumber"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        required />
                                </div>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" id="City"
                                    name="City"
                                    value={formData.City}
                                    onChange={handleChange}
                                    required />
                            </Form.Group>

                            <Button variant="primary" className='fbutton' type="submit" disabled={isLoading} > {isLoading ? 'Submitting...' : 'Submit'}
                            {isLoading}</Button>
                        </Form>
                    </div>
                )}
               
            </div>
        </div>
    );
}

export default LandingPage;
