import React, { useEffect, useState } from 'react'
import './HappyTourists.css'
import image1 from './images/image1.jpeg';
import image2 from './images/image2.jpeg';
import image3 from './images/image3.jpeg';
import { Button } from 'react-bootstrap';
import { BiSolidRightArrowCircle } from "react-icons/bi";


const HappyTourists = () => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    function calculateTimeLeft() {
        const deadline = new Date("May 31, 2024 23:59:59").getTime();
        const now = new Date().getTime();
        const difference = deadline - now;

        if (difference <= 0) {
            return {
                hours: '00',
                minutes: '00',
                seconds: '00'
            };
        }

        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        return {
            hours: formatTime(hours),
            minutes: formatTime(minutes),
            seconds: formatTime(seconds)
        };
    }
    function formatTime(time) {
        return time < 10 ? `0${time}` : time;
    }
  return (
<div className='gallary'>
    <h2 className='photoshead'>Our Happy Tourists</h2>
    <div className="image-card-container">

        <div className="image-card" >
            <img src={image1} alt="Image 1" className="card-image" />
        </div>

        <div className="image-card" >
            <img src={image2} alt="Image 2" className="card-image" />
        </div>

        <div className="image-card" >
            <img src={image3} alt="Image 3" className="card-image" />
        </div>
    </div>
    <div className='limited-offer-footer'>
    <div className='limited-offer-content'>
        <div className='limited-offer-box'>
            <p className='limited-offer-title'> Limited Time Offer</p>
            </div>
        <div className='limited-offer-timer'>
            <div className='timer-section'>
                <span className='timer-section-digit'>{timeLeft.hours}</span>
                <span className='timer-section-label'>Hours</span>
            </div>
            <div className='timer-section'>
                <span className='timer-section-digit'>{timeLeft.minutes}</span>
                <span className='timer-section-label'>Minutes</span>
            </div>
            <div className='timer-section'>
                <span className='timer-section-digit'>{timeLeft.seconds}</span>
                <span className='timer-section-label'>Seconds</span>
            </div>
        </div>
    </div>

       
            <a href='https://wa.me/61424388831' className='register-bt' ><Button className='register-button'><BiSolidRightArrowCircle /><span className='Register-button-text'>Register Now</span></Button></a>
     
</div>


</div>
  )
}

export default HappyTourists