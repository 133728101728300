import React, { useEffect, useState } from 'react'
import { IoMdCheckbox } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa6";
import './WhyChooseUsPage.css';

const WhyChooseUsPage = () => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    function calculateTimeLeft() {
        const deadline = new Date("May 31, 2024 23:59:59").getTime();
        const now = new Date().getTime();
        const difference = deadline - now;

        if (difference <= 0) {
            return {
                hours: '00',
                minutes: '00',
                seconds: '00'
            };
        }

        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        return {
            hours: formatTime(hours),
            minutes: formatTime(minutes),
            seconds: formatTime(seconds)
        };
    }
    function formatTime(time) {
        return time < 10 ? `0${time}` : time;
    }

  return (
    
<div class="why-choose-section">
        <h2 class='whychoose-head'>Why Choose Travel With Us</h2>
        <div class="row">
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'>Safety: Your safety is our top priority. We meticulously plan every aspect of the journey to ensure a secure and worry-free experience.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'>Expertise: Benefit from our years of experience organizing successful Kailash Yatras, ensuring a seamless and enriching pilgrimage.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'>Experienced Guides: Our knowledgeable and experienced guides accompany you throughout the journey, providing insights, support, and guidance at every step.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'>Spiritual Guidance: Our team includes spiritual leaders and experts who offer profound insights and guidance, enhancing the spiritual aspect of your pilgrimage.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'>Small Group Experience: Enjoy a more intimate and personalized pilgrimage experience with our small group sizes, allowing for deeper connections and meaningful interactions.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'>Customized Itineraries: We tailor our itineraries to suit the needs and preferences of our pilgrims, ensuring a fulfilling and memorable journey for each participant.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'>High-Quality Accommodation: Rest assured, we provide comfortable and well-appointed accommodation options, allowing you to rejuvenate and relax after each day's journey.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'>Seamless Logistics: Leave the logistics to us. From transportation to accommodations and meals, we handle every detail, allowing you to focus fully on your spiritual journey.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'>Cultural Immersion: Immerse yourself in the rich culture and traditions of the region with our carefully curated cultural experiences, enhancing the overall pilgrimage experience.</p>
                </span>
            </div>
            <div class="card">
                <span className='checkmark' ><IoMdCheckbox /></span>
                <span className='cardtext'> 
                <p class='cardhp'>Lasting Memories: Joining our Kailash Yatra is not just a journey; it's a transformative experience that leaves you with lifelong memories, spiritual growth, and newfound connections.</p>
                </span>
            </div>
        </div>
        <div className='countdownhead'>
        <h2 className='countdownheading'>Time is Running Out.Reserve Your Seat</h2>
        </div>
        <div className='Countdown-container'>
            <div className='countdown-item'>
                <span className='countdown-digit'>{timeLeft.hours}</span>
                <span className='countdown-label'>Hours</span>
            </div>
            <div className='countdown-item'>
                <span  className='countdown-digit'>{timeLeft.minutes}</span>
                <span className='countdown-label'>Minutes</span>
            </div>
            <div className='countdown-item'>
                <span  className='countdown-digit'>{timeLeft.seconds}</span>
                <span className='countdown-label'>Seconds</span>
            </div>
        </div>
        <p className='arrow'>  <FaArrowDown /></p>
        <button className='contact-button'>
         <a href='https://wa.me/61424388831'>
         <FaWhatsapp className='contact-icon' />
        <span className='contact-text'>Connect with Us</span>
         </a>
      </button>
    </div>
  )
}

export default WhyChooseUsPage